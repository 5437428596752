import React from 'react'
import { useSelector } from 'react-redux'

function Challange({ challange }) {

    const { language } = useSelector(state => state.global)

    return (
        <section className="overviewset">
            <div className="overblock">
                <h3>{challange.title[language]} </h3>
                <p>{challange.text[language]}</p>
            </div>
        </section>
    )
}

export default Challange
