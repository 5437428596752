import React from 'react'
import { useSelector } from 'react-redux'

const Banner = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const { casestudy_details: { banner } } = data[0]
  return (
    <section>
      <div className="casestudy_cg casestudy_vr" style={{ background: `url(${banner.backgroundImg.sourceUrl}) no-repeat`, backgroundPosition: 'center' }}>
        <div className="container">
          <img src={banner.clientLogo.sourceUrl} alt="client-logo" />
          <h1 style={{ whiteSpace: 'pre-wrap' }}>
            {banner.title[language]}
          </h1>
        </div>
      </div>
    </section>
  )
}
export default Banner
