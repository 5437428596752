/* eslint-disable */
import React from 'react'
import Banner from './Banner'
import Introduction from './Introduction'
import Overview from './Overview'
import ProjectOutcomes from './Outcome'
import Solution from './solution'

const index = ({ data }) => {
    const { confidentcrm_contents: { projectOutcomes, solution } } = data[0]

    return (
        <>
            <Banner data={data} />
            <Overview data={data} />
            <Introduction data={data} />
            <Solution solution={solution} />
            <ProjectOutcomes projectOutcomes={projectOutcomes} />
        </>
    )
}

export default index
