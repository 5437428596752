import React from 'react'
import { useSelector } from 'react-redux'

const Approach = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const {
    casestudy_details: {
      approach: {
        title, text, list, subTitle, subTitleTwo, paragraph, textTwo,
      },
    },
  } = data[0]

  return (
    <section className="approachset">
      <div className="overblock">
        <h2>{title[language]}</h2>
        <p>{text[language]}</p>
        {textTwo ? <p>{textTwo[language]}</p> : null}
        {subTitle
          ? <h2>{subTitle[language]}</h2> : null}
        <div className="box-listt">
          {list.map((item) => (
            <div className="box-itemm">
              <div className="tic" />
              <span className={item.text[language]} />
            </div>
          ))}
        </div>
        {subTitleTwo ? <h2>{subTitleTwo[language]}</h2> : null}
        {paragraph ? <p>{paragraph[language]}</p> : null}
      </div>

    </section>
  )
}

export default Approach
