import React from 'react'
import { useSelector } from 'react-redux'

function Solution({ solution }) {
    const language = useSelector(state => state.global.language)
    return (
        <section className="overviewset">
            <div className="overblock">
                <h3>{solution.title[language]} </h3>
                <p className="pb-4"> {solution.text1[language]} </p>
                <p className="pb-5">{solution.text2[language]} </p>
                <img src={solution.img?.sourceUrl} className="w-100" />
            </div>
        </section>
    )
}

export default Solution
