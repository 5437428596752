import React from 'react'
import { useSelector } from 'react-redux'

const Introduction = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const { casestudy_details: { introduction: { title, text } } } = data[0]
  return (
    <section className="beganset">
      <div className="overblock">
        <h2>{title[language]}</h2>
        <p>{text[language]}</p>
      </div>
    </section>
  )
}

export default Introduction
