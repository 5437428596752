import React from 'react'
import { useSelector } from 'react-redux';
import Slider from 'react-slick'


function Process({ process }) {

   const language = useSelector(state => state.global.language)
   const settings = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
         breakpoint: 992,
         settings: {
            slidesToShow: 2,
            infinite: true,
            autoplay: true,
         },
      }, {
         breakpoint: 700,
         settings: {
            slidesToShow: 1,
         },
      }],
   };

   return (
      <>
         <section className="overviewset partner-slick">
            <div className="overblockk">
               <h3>{process.title[language]} </h3>
               <div className="box-listt-counter">
                  <Slider className="row box-listt-counter m-0"  {...settings}>
                     {process.list.map(item => (
                        <div className="txt_numblock">
                           <div className="partner_number p_block">
                              <h3>{item.number} </h3>
                              <h2>{item.title[language]} </h2>
                              <ul>
                                 {item.li.map(point => (
                                    <li>{point.item[language]} </li>
                                 ))}
                              </ul>
                           </div>
                        </div>
                     ))}
                  </Slider>
               </div>
            </div>
         </section>
      </>
   )
}

export default Process
