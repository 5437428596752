import React from 'react'
import { useSelector } from 'react-redux'

function Abstract({ abstract }) {
    const language = useSelector(state => state.global.language)

    return (
        <section className="serviceset">
            <div className="overblock">
                <h3>{abstract.title[language]}</h3>
                <div className="row m-0 justify-content-between">
                    {abstract.list.map(item => (
                        <div className="case_study_block">
                            <ul className="pl-0">
                                <li>
                                    {item.title[language]} :
                                    <span>{item.text[language]}</span>
                                </li>
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Abstract
