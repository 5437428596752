/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux'

const solution = ({ solution }) => {
  const { language } = useSelector(state => state.global)

  return (
    <section className="overviewset">
      <div className="overblock has-n-block">
        <h3>{solution.title[language]}</h3>
        <p>{solution.text[language]} </p>
        <div className="box-listt">
          {solution.points.map(item => (
            <div className="box-itemm">
              <p> {item.item[language]}</p>
            </div>
          ))}
        </div>

        <p>{solution.textTwo[language]} </p>
        <div className="lead-info bg-white">
          <ul className="pl-0">
            {solution.list.map(item => (
              <li>
                <div className="tic-lead" />
                <span>{item.item[language]} </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section >
  )
}

export default solution
