import React from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'

const ImgSlider = ({ data }) => {
  const { casestudy_details: { slider } } = data[0]
  const { language } = useSelector(state => state.global)

  const imgs = slider.map((item) => item.img.sourceUrl)
  const slideSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    adaptiveHeight: true,
    centerMode: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '10%',
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        centerPadding: '3%',
      },

    }, {
      breakpoint: 319,
      settings: {
        slidesToShow: 1,
        centerPadding: '3%',
      },
    }],
  }
  const slides = (
    <Slider {...slideSettings}>
      {imgs.map((slide, index) => (
        <div key={index + 100}>
          <img src={imgs[index]} className="w-100" alt={`slider-img-${index + 1}`} style={{ border: '8px solid #eaeaea' }} />
        </div>
      ))}
    </Slider>
  )
  return (
    <>
      <section className="d-none d-sm-none d-md-block cgsliderr">
        <div className="containerrr" style={{ padding: '0 10%' }}>
          {slides}
        </div>
      </section>
      <section className="d-md-none d-lg-none d-xl-none d-block d-sm-block cgsliderr">
        <div className="container p-0">
          {slides}
        </div>
      </section>
    </>

  )
}

export default ImgSlider
