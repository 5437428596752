import React from 'react'
import { useSelector } from 'react-redux'

const Services = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const { casestudy_details: { services } } = data[0]
  return (
    <>
      {services.title && services.text && services.list.length > 0 ? (
        <section className="serviceset">
          <div className="overblock">
            <h3 className="split-text">
              {services.title[language]}
              {' '}
            </h3>
            <p className="split-text">
              {services.text[language]}
              {' '}
            </p>

            <div className="row m-0 justify-content-center">
              {services.list.map((service) => (
                <div className="case_study_block" key={service.text[language]}>
                  <a href={service.link}>
                    {service.text[language]}
                    <span className="arroww" />
                  </a>
                  <ul className="pl-0">
                    {service.bulletPoints.map((ser) => (
                      <li key={ser.li[language]}>{ser.li[language]}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </>
  )
}

export default Services
