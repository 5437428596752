import React from 'react'
import { useSelector } from 'react-redux'

function Conclusion({ conclusion }) {
    const language = useSelector(state => state.global.language)

    return (
        <section className="broughtset">
            <h2> {conclusion.title[language]} </h2>
            <div className="overblock">
                <p>{conclusion.text[language]} </p>
            </div>
        </section>
    )
}

export default Conclusion
