/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux';
import Slider from 'react-slick'


function Highlights({ projectOutcomes }) {
  const { language } = useSelector(state => state.global)

  const settings = {
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
      },
    }, {
      breakpoint: 319,
      settings: {
        slidesToShow: 1,
      },
    }],
  };

  return (
    <section className="approachset">
      <div className="overblock">
        <h2 className="pb-5">Project outcomes</h2>
        <ul className="box-listt-counter mb-0 pl-0" >
          <Slider className="mb-0 pl-0" {...settings}>
            {projectOutcomes.list.map(point => (
              <li style={{ margin: 20 }} className="box-itemm">
                <div className="li-list-item">
                  <span className="num-icon"></span>
                  {point.item[language]}
                </div>
              </li>
            ))}
          </Slider>
        </ul>
        <p className="m-2">
          {projectOutcomes.textOne[language]}
        </p>

      </div>

    </section>
  )
}

export default Highlights

