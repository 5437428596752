import React from 'react'
import { useSelector } from 'react-redux'

function Research({ research }) {
    const language = useSelector(state => state.global.language)
    return (
        <section className="approachset">
            <div className="overblock">
                <h2>{research.title[language]} </h2>
                <div className="box-listt flex-wrap">
                    {research.list.map(point => (
                        <div className="box-itemm mb-5">
                            <div className="tic"></div>
                            {point.item[language]}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Research
