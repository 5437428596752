import React from 'react'
import { useSelector } from 'react-redux'

const Results = ({ data }) => {
  const { language } = useSelector(state => state.global)

  const { casestudy_details: { results: { title, text } } } = data[0]
  return (
    <section className="broughtset">
      <h2>
        {title[language]}
      </h2>
      <div className="overblock">
        {text ? <p>{text[language]}</p> : null}
      </div>

    </section>
  )
}

export default Results
