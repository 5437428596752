import React from 'react'
import { useSelector } from 'react-redux'

const Overview = ({ data }) => {

  const { language } = useSelector(state => state.global)

  const {
    casestudy_details: {
      overview: { title, overviewText, overviewTextTwo },
    },
  } = data[0]
  return (
    <section className="overviewset">
      <div className="overblock">
        <h3>
          {title[language]}
        </h3>
        <p>{overviewText[language]}</p>
        {overviewTextTwo
          ? (
            <p className="pt-4">
              {overviewTextTwo[language]}
            </p>
          ) : null}
      </div>
    </section>
  )
}

export default Overview
