import React from 'react'
import { useSelector } from 'react-redux'

function Team({ team }) {
    const { language } = useSelector(state => state.global)

    return (
        <section className="beganset">
            <div className="overblock">
                <h2>{team.title[language]} </h2>
                <p className="pb-4">{team.text[language]}</p>
                <img src={team.img?.sourceUrl} className="w-100" />
            </div>
        </section>
    )
}

export default Team
