import React from 'react'
import Banner from './Banner'
import Team from './Team'
import Challange from './Challange'
import MarketResearch from './Research'
import Solution from './Solution'
import Highlights from './Highlights'
import Process from './Process'
import Abstract from './Abstract'
import Conclusion from './Conclusion'

function index({ data }) {
    const { casestudy_details: { banner },
        lyringo_contents: { challange, team, marketResearch, solutions, process,
            conclusion, abstract, projectHighlights } } = data[0]

    return (
        <div>
            <Banner banner={banner} />
            <Challange challange={challange} />
            <Team team={team} />
            <MarketResearch research={marketResearch} />
            <Solution solution={solutions} />
            <Highlights highlights={projectHighlights} />
            <Process process={process} />
            <Abstract abstract={abstract} />
            <Conclusion conclusion={conclusion} />
        </div>
    )
}

export default index
