import React from 'react'
import { useSelector } from 'react-redux';
import Slider from 'react-slick'


function Highlights({ highlights }) {
  const language = useSelector(state => state.global.language);
  const settings = {
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        infinite: true,
        autoplay: true,
      },
    }, {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    }],
  };

  return (
    <section className="approachset">
      <div className="overblock">
        <h2 className="pb-5">Project Highlights</h2>
        <ul className="box-listt-counter mb-0 pl-0" >
          <Slider className="mb-0 pl-0" {...settings}>
            {highlights.map(point => (
              <li style={{ margin: 20 }} className="box-itemm">
                <div className="li-list-item">
                  <span className="num-icon"></span>
                  {point.item[language]}
                </div>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </section>
  )
}

export default Highlights

